import React from 'react'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import Modal from '../../components/Modal'
import Link from '../../components/LinkWrapper'
import SocialButtons from '../../components/SocialButtons'
import i18n from '../../locales/he.yaml'

const WorkshopsThanksTemplate = ({ returnTo }) => {
  return (
    <StaticQuery
      query={graphql`
        query WorkshopsThanksQuery {
          balloons: file(relativePath: { eq: "בלונים.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 280
                placeholder: BLURRED
                blurredOptions: { width: 80 }
                layout: FIXED
              )
            }
          }
        }
      `}
      render={({ balloons }) => {
        return (
          <div className='container is-flex direction-column items-center content'>
            <div>
              <div className='is-flex justify-center'>
                <GatsbyImage image={getImage(balloons)} />
              </div>
              {i18n.workshopsThanks.quote.map((text, index) => (
                <p key={index}>{text}</p>
              ))}
              <p className='has-text-gold-black has-text-weight-bold'>
                {i18n.workshopsThanks.conclusion}
              </p>
              <p>{i18n.workshopsThanks.inviteToGroup}</p>
              <p className='is-flex justify-center'>
                <a
                  href='https://www.facebook.com/groups/karinnemanuel'
                  className='button is-nude is-flex items-center justify-start'
                  target='_blank'
                  rel='noreferrer'
                >
                  <span className='icon is-margin-right-2'>
                    <FontAwesomeIcon icon={faFacebookF} />
                  </span>
                  {i18n.workshopsThanks.inviteToGroupButton}
                </a>
              </p>
              <p>{i18n.workshopsThanks.inviteToSocialNetworks}</p>
              <p className='is-flex justify-center is-margin-top-4'>
                <SocialButtons isColor isLarge />
              </p>
              <p className='has-text-centered'>
                <Link
                  to={returnTo || '/workshops/'}
                  state={{ noScroll: returnTo === '/workshops/' }}
                  className='button is-text is-link-ka'
                >
                  {i18n.workshopsThanks.callToAction}
                </Link>
              </p>
            </div>
          </div>
        )
      }}
    />
  )
}

const WorkshopsThanks = ({ location: { pathname } }) => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) =>
      modal ? (
        <Modal isOpen>
          <div className='box'>
            <WorkshopsThanksTemplate returnTo={closeTo} />
          </div>
        </Modal>
      ) : (
        <Layout
          callToActionPath={'/workshops/'}
          callToActionText={i18n.workshopsThanks.callToAction}
        >
          <Seo
            metadata={i18n.workshopsThanks.metadata}
            pathname={pathname}
            robots='noindex, nofollow'
          />
          <div className='hero has-navbar-fixed-top'>
            <div className='hero-body'>
              <WorkshopsThanksTemplate />
            </div>
          </div>
        </Layout>
      )
    }
  </ModalRoutingContext.Consumer>
)

export default WorkshopsThanks
